<template>
    <div class="myMessage">
        <!-- <div class="messageItem">
            <div class="itemTop">
                <div class="left">
                    <span>发布人员：admin</span>
                    <span>发送时间：</span>
                </div>
                <div class="right">删除</div>
            </div>
            <div class="msg">尊敬的用户，为了给您带来更好的体验  秒杀专区业务将于2017年4月26日22：00-2017年4月27日 09:00期间进行系统升级，升级期间秒杀专区入口将暂时下线。升级后可正常充值和查看充值记录，请有需要的用户提前充值或者在系统升级后再充值。给您带来的不便，敬请谅解。感谢您一如既往的支持。</div>
        </div> -->

        <div class="messageItem" v-for="(item,i) in msgList" :key="item.messageLogId">
            <div class="itemTop">
                <div class="left">
                    <span>发布人员：{{item.publisher}}</span>
                    <span>发送时间：{{item.createTime}}</span>
                </div>
                <div class="right" @click="clickDel(item,i)">删除</div>
            </div>
            <div class="msg">{{item.content}}</div>
        </div>
    </div>
</template>

<script>
import {getMyMessageList,delMessage} from "@/axios/api"
export default {
    data(){
        return{
            msgList:[],
        }
    },
    methods: {
        gainMyMessageList(){
            getMyMessageList({
                page:1,
                size:100,
                phone:"15162066057"
            }).then(res=>{
                // console.log("我的消息",res);
                if(res.code==200){
                    this.msgList=res.data.rows;
                }
            })
        },
        // 删除消息
        clickDel(item,i){
            delMessage({messageLogId:item.messageLogId}).then(res=>{
                // console.log("删除消息结果",res);
                if(res.code==200){
                    this.$message.success(res.msg||res.message);
                    
                    this.msgList.splice(i,1)
                }else{
                    this.$message(res.msg||res.message);
                }
            })
        }
    },
    created() {
        this.gainMyMessageList();
    },
}
</script>

<style lang="less" scoped>
.myMessage{
    background: rgba(247, 247, 247, 1);
    padding: 10px;
    .messageItem{
        background: #fff;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        .itemTop{
            padding: 12px 35px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 400;
            color: rgba(128, 128, 128, 1);
            border-bottom: 1px solid rgba(229, 229, 229, 1);
            .left{
                &>span{
                    margin-right: 20px;
                }
            }
            .right{
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 87, 51, 1);
                cursor: pointer;
            }
        }
        .msg{
            padding: 12px 35px;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 31px;
            color: rgba(128, 128, 128, 1);
        }
    }
}
</style>